.page8 {
    width: 100%;
    position: relative;
    background-color: var(--bg_green);
}

.page8 .content {
    position: relative;
    z-index: 9;
}
.page8 .content .about {
    padding-top: 261px;
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    color: var(--text_title);
    font-family: futura-pt;
}

.page8 .content .make-the-future-with-us {
    font-family: futura-pt;
    margin-top: 94px;
    font-size: 70px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    color: #ffffff;
}


.page8 .map-wrapper {
    display: flex;
    margin: 120px auto 200px;
}
.page8 .visit-us {
    font-family: Noto Sans;
    margin: auto auto auto 91px;
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    text-align: left;
    color: var(--text_page4);
}

.page8 .visit-us>div:first-child {
    margin-bottom: 91px;
}
.page8 .visit-us .en {
    font-family: futura-pt;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.29;
}


.page8 .content .info {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: var(--text_title);
    font-family: Noto Sans;
    line-height: 0.89;
}

.page8 .content .info-sub {
    margin-top: 22px;
    font-size: 15px;
    text-align: left;
    color: var(--text_title);
    font-family: Noto Sans;
    line-height: 1.07;
    font-weight: bold;
}

.page8 .content .info-sub span {
    font-weight: normal;
    margin-left: 17px;
}
.page8 .content .info-dtl{
    margin-top: 17px;
}
.page8 .content .info-dtl .row {
    display: flex;
    flex-direction: row;
}

.page8 .content .info-dtl .icon {
    width: 12px;
    height: 12px;
    margin: auto 0;
    background-size: contain;
    background-repeat: no-repeat;
}


.page8 .content .info-dtl .icon.mail {
    background-image: url(icon-mail@3x.png);
}

.page8 .content .info-dtl .icon.fax {
    background-image: url(icon-fax@3x.png)

}

.page8 .content .info-dtl .icon.phone {
    background-image: url(icon-phone@3x.png);
}


.page8 .content .info-dtl .icon.addr {
    background-image: url(icon-loca@3x.png)
}

.page8 .content .info-dtl .row .text {
    font-size: 15px;
    text-align: left;
    font-family: Noto Sans;
    font-weight: 700;
    line-height: 2;
    color: var(--text_title);
}

.page8 .content .info-dtl .row .text span {
    margin-left: 18px;
    font-weight: 300;
}

.page8 .content .sns {
    margin-right: 0;
    display: flex;
    flex-direction: row;
    margin-left: auto;
}

.page8 .content .sns .instagram {
    background: url(icon-in@3x.png) no-repeat;
    background-size: contain;
    width: 31px;
    height: 31px;
    margin-right: 44px;
    cursor: pointer;
}

.page8 .content .sns .facebook {
    background: url(icon-f@3x.png) no-repeat;
    background-size: contain;
    width: 31px;
    height: 31px;
    cursor: pointer;
}

.page8 .content .copyright {
    margin-top: auto;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.58;
    text-align: left;
    color: var(--text_eng);
}

.page8 .footer {
    background-color: #e7e7e5;
    padding: 42px 0;
}

.page8 .footer .content {
    width: 1050px;
    margin: auto;
    display: flex;
    flex-direction: row;
}

.page8 .footer .content .footer-left {
    margin-right: auto;
}

.page8 .footer .content .footer-right {
    display: flex;
    margin-left: auto;
    flex-direction: column;
}