.page3 {
    display: flex;
    flex-direction: column;
    background-color: var(--light-grey);;
    height: 4500px;
    position: relative;
}

.page3 .content {
    position: absolute;
    left: 50vw;
    transform: translate(-50%, -50%);
    display: flex;
    width: 1190px;
    flex-direction: row;
    top: 50%;
    height: unset;
}



.page3 .content .align-box {
    position: relative;
    width: 50%;
    height: 195px;

}
.page3 .content .align-box .letter-m {
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: url(M_black@3x.png) center no-repeat;
    background-size: contain;
    height: 350px;
    width: 350px;
    margin: 0;
}

.page3 .content .align-box .letter-o {
    position: absolute;
    background: url(0_black@3x.png) center no-repeat;
    background-size: contain;
    width: 350px;
    height: 350px;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}


.page3 .content .align-box .m-title {
    opacity: 0;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: var(--text_title);;

    font-size: 44.5px;
    font-weight: 500;
    line-height: 1.19;
    text-align: center;
}

.page3 .content .align-box .o-title {
    opacity: 0;
    width: 100%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: var(--text_title);;

    font-size: 44.5px;
    font-weight: 500;
    line-height: 1.19;
    text-align: center;
}
.page3 .content .align-box .m-desc {
    opacity: 0;
    position: absolute;
    width: 100%;
    top: 75%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: var(--text_title);
}

.page3 .content .align-box .o-desc {
    opacity: 0;
    position: absolute;
    width: 100%;
    top: 25%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: var(--text_title);
}


.page3 .content .align-box .cnt {
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
    font-family: Noto Sans;
    font-size: 25px;
    color: var(--text_page4);
}

.page3 .content .align-box .eng {
    font-size: 20px;
    text-align: center;
    font-family: futura-pt;
    font-weight: 300;
    line-height: 1.37;
    color: var(--text_page4);
}


.page3 .content .align-box .m-title, .page3 .content .align-box .o-title {
    font-family: futura-pt;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 60px;
    line-height: 1.2;
    text-align: center;
    font-weight: 500;
    color: var(--text_page4);

}
.page3 .content .align-box .m-desc, .page3 .content .align-box .o-desc {
    opacity: 0;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: var(--text_title);;
}
