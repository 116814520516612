.project {
    flex: 0 0 auto;
    margin-right: 69px;
    width: 500px;
    margin-bottom: 50px;
}

.project:nth-child(2n) {
    margin-right: 0;
}

.project .title {
    margin-top: 45px;
    font-weight: bold;
    text-align: left;
    font-family: Noto Sans;
    font-size: 32px;
    line-height: 1.28;
    color: var(--text_page4);
}

.project .subtitle {
    font-weight: 300;
    text-align: left;
    font-family: futura-pt;
    font-size: 20px;
    line-height: 25px;
    color: var(--text_page4);
}

.project .content {
    margin-top: 42px;
    text-align: left;
    font-family: Noto Sans;
    font-size: 15px;
    line-height: 1.47;
    color: var(--text_page4);
}

.project .content > div {
    margin-bottom: 21px
}

.project .image {
    width: 100%;
    background-size: cover;
    padding-bottom: 100%;
}