.page3-m {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--light-grey);
    overflow: hidden;
    height: 1106px;
}

.page3-m .wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}


.page3-m .content {
    margin: 0 auto 281px;
}
.page3-m .content .letter-m {
    background-size: contain;
    background: url(M_black@3x.png) center no-repeat;
    background-size: contain;
    height: 200px;
    width: 200px;
    margin: auto;
}

.page3-m .content .letter-0 {
    background-size: contain;
    background: url(0_black@3x.png) center no-repeat;
    background-size: contain;
    height: 200px;
    width: 200px;
    margin: auto;
}

.page3-m .content .cnt {
    margin-top: 17px;
    font-family: Noto Sans;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: var(--text_page4);
}

.page3-m .content .desc {
    margin-top: 12px;
    font-family: futura-pt;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.29;
    text-align: center;
    color: var(--text_page4);
}
