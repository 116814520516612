.page1-m {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.page1-m .background {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1000;
}

.page1-m .wrapper {
    margin-top: -40px;
    width: 100%;
    height: 100%;
    display: flex;

}

.page1-m .content {

    margin: auto;
    display: flex;
}

.page1-m .content .letter-m {
    background: url(M_white@3x.png) center no-repeat;
    background-size: contain;
    width: 166px;
    height: 166px;
    margin: auto;
}

.page1-m .content .letter-o {
    background: url(0_white@3x.png) center no-repeat;
    background-size: contain;
    width: 166px;
    height: 166px;
    margin: auto;
}
