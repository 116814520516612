.page5-m {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background: url("bg@3x.png") center center no-repeat;
    background-size: cover;
    position: relative;
}

.page5-m .wrapper {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.page5-m .about {
    font-family: futura-pt;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.24;
    letter-spacing: normal;
    color: var(--light-grey);
    margin: auto auto 327px;
}

.page5-m .cnt {
    font-family: Noto Sans;
    font-size: 14px;
    line-height: 1.36;
    color: #ffffff;
    margin: 0 auto auto;
}
