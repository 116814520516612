.page4 {
    display: flex;
    flex-direction: column;
    background-position-y: 0;
    overflow-x: hidden;
    height: 4000px;
    position: relative;
}

.page4 .wrapper {
    position: absolute;
    top: 600px;
    width: 100%;
    height: auto;
}

.page4 .content {
    position: absolute;
    top: 50vh;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.page4 .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 200vh;
    background-image: linear-gradient(var(--light-grey),var(--light-grey) 33%, var(--bg_green) 66%, var(--bg_green) 100%);
    background-size: 100% 300%
}

.page4 .content .slide-wrapper {
    width: 5000px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

.page4 .content .design {
    background: url(design-solid@3x.png) no-repeat top center;
    transition: background-image 0.2s ease-in-out;
    background-size: contain;
    width: 605px;
    height: 210px;
}

.page4 .content .design.stroke {
    background-image: url(design-stroke@3x.png);
}

.page4 .content .material {
    background: url(material-solid@3x.png) no-repeat top center;
    transition: background-image 0.2s ease-in-out;
    background-size: contain;

    margin-left: calc(50vw - 365px);
    margin-right: 115px;
    width: 730px;
    height: 170px;
}

.page4 .content .material.stroke {
    background-image: url(material-stroke@3x.png);
}

.page4 .content .driven {
    background: url(driven-solid@3x.png) no-repeat  top center;
    transition: background-image 0.2s ease-in-out;
    background-size: contain;
    width: 605px;
    height: 170px;
    margin-right: 115px;
}

.page4 .content .driven.stroke {
    background-image: url(driven-stroke@3x.png);
}

.page4 .content .title {
    text-align: center;
    margin-top: 38px;
    margin-bottom: 78px;
    font-family: futura-pt;
    font-size: 38px;
    font-weight: 500;
    color: var(--text_page4);
}

.page4 .content .title span {
    font-family: AppleGothic;
}
.page4 .content .desc {
    text-align: center;
    font-family: Noto Sans;
    font-size: 20px;
    line-height: 31px;
    color: var(--text_page4);
}