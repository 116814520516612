.project-m {
    flex: 0 0 auto;
    margin: 0 24px 50px;
}

.project-m .title {
    margin-top: 24px;
    font-weight: bold;
    text-align: left;
    font-family: Noto Sans;
    font-size: 23px;
    line-height: 26px;
    color: var(--text_page4);
}

.project-m .subtitle {
    margin-top: 4px;
    font-weight: 300;
    text-align: left;
    font-family: futura-pt;
    font-size: 14px;
    line-height: 19px;
    color: var(--text_page4);
}

.project-m .content {
    margin-top: 24px;
    transition: opacity .2s;
    width: 100%;
    height: 100%;
    text-align: left;
    font-family: Noto Sans;
    font-size: 14px;
    line-height: 20px;
}

.project-m .content > div {
    margin-bottom: 21px
}

.project-m .image {
    position: relative;
    width: 100%;
    background-size: cover;
    padding-bottom: 100%;
}