.page6 {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--light-grey);;
    height: 4350px;
}

.page6 .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
}

.page6 .content {
    margin: auto;
}


.page6 .content .about {
    font-weight: 500;
    text-align: center;
    color: var(--text_title);
    font-family: futura-pt;
    font-size: 26px;
    line-height: 36px;
}



.page6 .box-wrapper {
    position: relative;
    height: 513px;
    width: 1190px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}


.page6 .content .align-box {
    position: relative;
    width: 50%;

}

.page6 .content .align-box .desc {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: var(--text_title);;
    font-size: 60px;
    line-height: 1.2;

    text-align: center;
}


.page6 .content .align-box .letter-o {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: url(0_black@3x.png) center no-repeat;
    background-size: contain;
    width: 350px;
    height: 350px;
}

.page6 .content .align-box .letter-p {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%,-50%);
    background: url(P_black@3x.png) center no-repeat;
    background-size: contain;
    width: 350px;
    height: 350px;
    margin: auto;

    margin-top: -4px;
}

.page6 .content .align-box .letter-v {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: url(V_black@3x.png) center no-repeat;
    background-size: contain;
    width: 350px;
    height: 350px;
    margin: auto;
}


.page6 .content .align-box .p-desc, .page6 .content .align-box .v-desc {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    top: 50%;
    margin: auto;
    color: var(--text_title);
    font-size: 44.5px;
    font-weight: 500;
    line-height: 1.19;
    text-align: center;
}

.page6 .content .align-box .title {
    font-weight: 500;
    text-align: center;
    font-family: futura-pt;
    font-size: 60px;
    line-height: 1.2;
    color: var(--text_page4);
}

.page6 .content .align-box .cnt {
    margin-top: 32px;
    font-weight: bold;
    text-align: center;
    font-family: Noto Sans;
    font-size: 25px;
    line-height: 1.4;
    color: var(--text_page4);
}

.page6 .content .align-box .eng {
    margin-top: 7px;
    font-weight: 300;
    text-align: center;
    font-family: futura-pt;
    font-size: 20px;
    line-height: 28px;
    color: var(--text_page4);
}
