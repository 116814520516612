.page8-m {
    width: 100%;
    position: relative;
    background-color: var(--bg_green);
}

.page8-m .background {
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--bg_green);
}

.page8-m .content {
    position: relative;
    z-index: 9;
}
.page8-m .content .about {
    padding-top: 104px;
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: var(--text_title);
    font-family: futura-pt;
}

.page8-m .content .make-the-future-with-us {
    font-family: futura-pt;
    margin-top: 50px;
    font-size: 36px;
    font-weight: 500;
    line-height: 45PX;
    text-align: center;
    color: #ffffff;
}


.page8-m .map-wrapper {
    display: flex;
    flex-direction: column;
    margin: 82px auto;
}
.page8-m .visit-us {
    font-family: Noto Sans;
    margin: 0 24px;
    padding: 82px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--text_page4);
    display: flex;
    flex-direction: row;
}

.page8-m .visit-us>div:nth-child(2) {
    margin-left: auto;
}
.page8-m .visit-us .en {
    font-family: futura-pt;
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
}

.page8-m .content .info {
    font-size: 14.5px;
    font-weight: bold;
    text-align: left;
    color: var(--text_title);
    font-family: Noto Sans;
    line-height: 1.24;
}

.page8-m .content .info-sub {
    margin-top: 15px;
    font-size: 12px;
    text-align: left;
    color: var(--text_title);
    font-family: Noto Sans;
    line-height: 1.58;
    font-weight: 700;
}

.page8-m .content .info-sub span {
    font-weight: 300;
    margin-left: 17px;
}
.page8-m .content .info-dtl{

    margin-top: 17px;
}
.page8-m .content .info-dtl .row {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
}

.page8-m .content .info-dtl .icon {
    width: 12px;
    height: 12px;
    margin: auto 0;
    background-size: contain;
    background-repeat: no-repeat;
}


.page8-m .content .info-dtl .icon.mail {
    background-image: url(icon-mail@3x.png);
}

.page8-m .content .info-dtl .icon.fax {
    background-image: url(icon-fax@3x.png)

}

.page8-m .content .info-dtl .icon.phone {
    background-image: url(icon-phone@3x.png);
}


.page8-m .content .info-dtl .icon.addr {
    background-image: url(icon-loca@3x.png)
}

.page8-m .content .info-dtl .row .text {
    font-size: 12px;
    text-align: left;
    font-family: Noto Sans;
    font-weight: 700;
    line-height: 1.58;
    color: var(--text_title);
}

.page8-m .content .info-dtl .row .text span {
    margin-left: 12px;
    font-weight: 300;
}

.page8-m .content .sns {
    margin-top: 51px;
    margin-right: 0;
    display: flex;
    flex-direction: row;
    margin-left: auto;
}

.page8-m .content .sns .instagram {
    background: url(icon-in@3x.png) no-repeat;
    background-size: contain;
    width: 23px;
    height: 23px;
    margin-right: 12px;
    cursor: pointer;
}

.page8-m .content .sns .facebook {
    background: url(icon-f@3x.png) no-repeat;
    background-size: contain;
    width: 23px;
    height: 23px;
    cursor: pointer;
}

.page8-m .content .copyright {
    margin-top: 49px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.58;
    text-align: left;
    color: var(--text_eng);
}

.page8-m .footer {
    background-color: #e7e7e5;
    padding: 55px 26px;
}

.page8-m .footer .content {
    margin: auto;
}
