.page6-m {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 1248px;
    background-color: var(--light-grey);
    position: relative;
}

.page6-m .wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: column;
}

.page6-m .content {
    margin: 0 auto 281px;
}
.page6-m .about {
    margin-top: 142px;
    font-family: futura-pt;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.24;
    letter-spacing: normal;
    color: var(--text_title);
    margin-bottom: 20px;
}

.page6-m .content .text {
    font-family: futura-pt;
    color: var(--text_page4);
    text-align: center;
    margin-top: 36px;
    font-size: 18px;
    line-height: 1.17;
    font-weight: 500;
}

.page6-m .content .cnt {
    margin-top: 76px;
    font-family: Noto Sans;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: var(--text_page4);
}

.page6-m .content .desc {
    margin-top: 13px;
    font-family: futura-pt;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.29;
    text-align: center;
    color: var(--text_page4);
}


.page6-m .letter-o {
    background: url(0_black@3x.png) center no-repeat;
    background-size: contain;
    width: 150px;
    height: 150px;
    margin: 0 auto;
}


.page6-m .letter-p {
    background: url(P_black@3x.png) center no-repeat;
    background-size: contain;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    margin-top: -4px;
}


.page6-m .letter-v {
    background: url(V_black@3x.png) center no-repeat;
    background-size: contain;
    width: 150px;
    height: 150px;
    margin: 0 auto;
}
