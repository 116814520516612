.page7 {
    overflow-x: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--light-grey);
    position: relative;
}

.page7 .wrapper {
    position: relative;
    overflow-y: visible;
    width: 100%;
    height: 3600px;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
}
.page7 .section {
    position: relative;
    margin-top: 198px;
    display: flex;
    flex-direction: column;
}
.page7 .section-title {
    font-size: 25px;
    font-weight: bold;
    line-height: 35px;
    text-align: center;
    color: var(--text_title);
    font-family: Noto Sans;
}

.page7 .section-subtitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    color: var(--text_title);
    font-family: futura-pt;
}

.page7 .project-wrapper {
    width: 1096px;
    overflow-x: hidden;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
}
