.topbar {
    display: flex;
    flex-direction: row;
    z-index: 999;
    position: fixed;
    right: 0px;
    top: 45px;
}


.topbar .menu {
    cursor: pointer;
    font-family: futura-pt;
    margin-right: 82px;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.33;
    text-align: left;
    color: rgba(255, 255, 255, 0.5);
}

.topbar .menu:hover {
    color: #ffffff;
    font-weight: 500;
}

.topbar.dark .menu {
    color: rgba(50, 50, 50, 0.5);
}

.topbar.dark .menu.focused {
    color: var(--text_title);
}



.topbar .side {
    position: fixed;
    top: calc(50%);
    transform: translateY(-50%);
    right: 72px;
}

.topbar .side .button {
    cursor: pointer;
    background-image: url(5@3x.png);
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;

}

.topbar .side .button.active {
    background-image: url(4@3x.png);
}

.topbar.dark .side .button {
    background-image: url(1@3x.png);
}

.topbar.dark .side .button.active {
    background-image: url(2@3x.png);
}