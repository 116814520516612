
.topbar-m {
    position: fixed;
    z-index: 999;
}
.topbar-m > div {
    transition: transform 0.2s;
}

.topbar-m .icon {
    position: fixed;
    right: 25px;
    top: 25px;
    background: url(icon-menu@3x.png) no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
}

.topbar-m.white .icon {
    background-image: url(icon-menu_white@3x.png);
}

.topbar-m .icon.opened {
    background-image: url(icon-close@3x.png);
}

.topbar-m .background {
    transform: translateX(200%);
    position: fixed;
    margin-left: auto;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 100%);
}

.topbar-m .menu {
    transform: translateX(200%);
    position: fixed;
    font-family: futura-pt;
    top: 92px;
    right:23px;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.37;
    text-align: right;
    color: #ffffff;
}

.topbar-m .background.opened {
    transform: translateX(0);
    opacity: 0.6;
}
.topbar-m .menu.opened {
    transform: translateX(0);
    opacity: 1;
}

.topbar-m .menu > div{
    margin-bottom: 41px;
}
.topbar-m .menu .selected {
    color: rgba(255, 255, 255, 0.5);
}



.topbar-m {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    z-index: 999;
    position: fixed;
    right: 17px;
    top: 64px;
}


.topbar-m .menu:hover {
    font-weight: 500;
}
