.page5 {
    position: relative;
    width: 100%;
    height: 100vh;
}

.page5 .wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-image: url("bg@2x.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.page5 .wrapper .content {
    margin: auto;
}

.page5 .wrapper .content .part1 {
    width: 100%;
}

.page5 .wrapper .content .part1 .about {
    font-family: futura-pt;
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    color: var(--light-grey);
}

.page5 .wrapper .content .part1 .desc {
    padding-top: 478px;
    font-size: 25px;
    line-height: 1.4;
    text-align: center;
    color: #ffffff;
    font-family: Noto Sans;
}