.page7-rnd-m {
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--light-grey);
    position: relative;
    padding-bottom: 50px;
}

.page7-rnd-m .wrapper {
    position: relative;
    overflow-y: visible;
    width: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
}
.page7-rnd-m .section {
    position: relative;
}
.page7-rnd-m .section-title {
    margin-top: 89px;
    font-size: 15px;
    font-weight: bold;
    line-height: 19px;
    text-align: center;
    color: var(--text_title);
    font-family: Noto Sans;
}

.page7-rnd-m .section-subtitle {
    margin-bottom: 48px;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    color: var(--text_title);
    font-family: futura-pt;
}
