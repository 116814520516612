.page4-m {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background-color: var(--bg_green);
    position: relative;
}

.page4-m .wrapper {
    position: absolute;
    margin-top: -50px;
    top: 0;
    width: 100%;
    bottom: 0;
    display: flex;
}

.page4-m .content {
    margin: auto;
}



.page4-m .content .material {
    background: url(material-solid@3x.png) no-repeat top center;
    transition: background-image 0.2s ease-in-out;
    background-size: contain;
    width: 167px;
    height: 40px;
    margin: 0 auto 13px;
}


.page4-m .content .driven {
    background: url(driven-solid@3x.png) no-repeat  top center;
    transition: background-image 0.2s ease-in-out;
    background-size: contain;
    width: 126px;
    height: 40px;
    margin: 0 auto 13px;
}


.page4-m .content .design {
    background: url(design-solid@3x.png) no-repeat top center;
    transition: background-image 0.2s ease-in-out;
    background-size: contain;
    width: 128px;
    height: 48px;
    margin: 0 auto 13px;
}

.page4-m .content .desc {
    margin-top: 88px;
    font-family: Noto Sans;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--text_page4);
}