.page2 {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--light-grey);
    height: 100vh;
}

.page2 .wrapper {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.page2 .content {
    margin: auto;
}

.page2 .content .text {
    font-family: Noto Sans;
    color: var(--text_page4);
    text-align: center;
    margin-top: 32px;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.28;
}

.page2 .content .text span {
    font-family: futura-pt;
    font-weight: 500;
}

.page2 .content .desc {
    font-family: futura-pt;
    color: var(--text_page4);
    text-align: center;
    margin: 40px 0 0;
    font-size: 20px;
    line-height: 1.4;
}
