.page1 {
    position: relative;

    width: 100%;
    height: 100vh;
}

.page1 .background {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1000;
    overflow: hidden;
}

.page1 .wrapper {
    width: 100%;
    height: 100%;
    display: flex;

}

.page1 .content {
    width: 1190px;
    margin: auto;
    display: flex;
}

.page1 .content .letter-m {
    background: url(M_white@3x.png) center no-repeat;
    background-size: contain;
    width: 350px;
    height: 350px;
    margin: auto;
}

.page1 .content .letter-o {
    background: url(0_white@3x.png) center no-repeat;
    background-size: contain;
    width: 350px;
    height: 350px;
    margin: auto;

}
