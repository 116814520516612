.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.panel{
  height: 100vh;
  scroll-snap-align: start;
}

.m0-section {
  scroll-snap-align: start;
}

.red {
  background-color: #cf3535;
  background-image: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: inherit;
  text-decoration: inherit;
}

#toast {
  position: fixed;
  bottom: 30px;
  left: 50%;
  padding: 10px;
  transform: translate(-50%, 10px);
  border-radius: 5px;
  overflow: hidden;
  font-size: .8rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s, visibility .5s, transform .5s;
  background: rgba(0, 0, 0, .35);
  color: #fff;
  z-index: 10000;
}

#toast.reveal {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, 0)
}


:root {
  --bg_green: #33b684;
  --text_title: #323232;
  --text_eng: #636261;
  --text_page7title: #121212;
  --light-grey: #ededeb;
  --text_page4: #000000;
}